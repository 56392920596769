import React, { useState } from "react";
import { Auth } from "aws-amplify";
import Field from "./Field";
import LoadButton from "../LoadButton";
import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import Slide from '@material-ui/core/Slide';
import { SessionContext } from "../../App";
import SignInNewPassword from "./SignInNewPassword";
import SignInConfirm from "./SignInConfirm";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
  root: {
    "& .MuiTextField-root": {
      width: "100%",
      marginBottom: 15
    }
  },
  title: {
    textAlign: "center"
  },
  signInButton: {
    width: "100%"
  }
});

const SignIn: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const session = React.useContext(SessionContext);
  const [challengeUser, setChallengeUser] = useState();

  const startSession = (user: any) => {
    session.setUser(user);
  };

  const onSignIn = () => {
    if (!username || !password) {
      setError("Username and password are required.");
      return;
    }
    setLoading(true);
    Auth.signIn({ username, password })
      .then(user => {
        if (user.challengeName) {
          setChallengeUser(user);
        } else {
          startSession(user);
        }
      })
      .finally(() => setLoading(false))
      .catch(err => {
        switch (err.name) {
          case "UserNotFoundException":
            setError("Incorrect username or password.");
            break;
          default:
            setError("An unknown error occured.");
            console.error(err);
        }
      })
  };

  const handleCompleteNewPassword = () => startSession(challengeUser);
  const handleConfirm = () => startSession(challengeUser);

  const isError = error.length > 0;
  const challenge = challengeUser ? getChallengeType(challengeUser) : null;

  return (
    <Box className={classes.root}>
      {!challenge &&
        <>
          <Field input={["Username", username, setUsername]} error={isError} />
          <Field input={["Password", password, setPassword]} type="password" error={isError} />
          <LoadButton
            className={classes.signInButton}
            color="primary"
            variant="contained"
            onClick={onSignIn}
            loading={loading}
          >
            Sign In
          </LoadButton>
          <Slide direction="up" in={isError} mountOnEnter unmountOnExit>
            <FormHelperText error={isError}>{error}</FormHelperText>
          </Slide>
        </>
      }
      {challenge === "NEW_PASSWORD" &&
        <SignInNewPassword
          challengeUser={challengeUser}
          onCompleteNewPassword={handleCompleteNewPassword}
        />
      }
      {challenge === "MFA" &&
        <SignInConfirm
          challengeUser={challengeUser}
          onConfirm={handleConfirm}
        />
      }
    </Box>
  )
};

const getChallengeType = (challengeUser: any) => {
  switch(challengeUser.challengeName) {
    case "SMS_MFA":
    case "SOFTWARE_TOKEN_MFA":
      return "CONFIRM";
    case "NEW_PASSWORD_REQUIRED":
      return "NEW_PASSWORD";
    default:
      return challengeUser.challengeName;
  }
}

export default SignIn;