import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Amplify, { Auth } from "aws-amplify";
import SignIn from "./components/SignIn";
import theme from "./theme";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Dashboard from "./components/Dashboard";

Amplify.configure({
  region: "eu-west-1",
  userPoolId: "eu-west-1_vlVXhLZ4e",
  userPoolWebClientId: "7o7a7nlqht6rgp38qfmp3ag87h",
});

const useStyles = makeStyles({
  root: {
    padding: 0,
  },
  signInTitle: {
    marginTop: 180,
    textAlign: "center",
    marginBottom: 20
  },
  signInBox: {
    margin: "0 auto 0",
    maxWidth: 300,
    padding: 20,
    overflow: "hidden"
  }
});

export type SeccionContextProps = {
  user: any;
  setUser: (user: any) => void;
};

export const SessionContext = React.createContext<SeccionContextProps>({
  user: undefined,
  setUser: () => {}
});

const App: React.FC = () => {
  const [user, setUser] = useState();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(newUser => setUser(newUser))
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [setUser]);

  const sessionContext = {
    user,
    setUser: (newUser: any) => {
      setUser(newUser);
      if (!newUser) {
        Auth.signOut().catch(err => console.error(err));
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SessionContext.Provider value={sessionContext}>
        <Container maxWidth={false} className={classes.root}>
          {!loading && !user &&
            <>
              <Typography variant="h5" component="h2" className={classes.signInTitle}>
                Sign In to The List
              </Typography>
              <Paper className={classes.signInBox} variant="outlined">
                <SignIn />
              </Paper>
            </>
          }
          {!loading && user && <Dashboard />}
        </Container>
      </SessionContext.Provider>
    </ThemeProvider>
    
  );
}

export default App;
