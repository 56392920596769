import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Profile from "./Profile";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    // height: 50,
    backgroundColor: "#ccc",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 16
  },
  detail: {
    position: "relative",
  },
  title: {
    
  },
  profile: {
    
  }
});

type DetailProps = {
  className: string,
  [key: string]: any
};

const Detail: React.FC<DetailProps> = ({ children, ...props }) => {
  const classes = useStyles();
  return <Box component="div" className={classes.detail} {...props}>{children}</Box>;
}

const Header = () => {
  const classes = useStyles();
  return (
    <Box component="header" className={classes.root}>
      <Typography variant="h5" component="h1" className={classes.title}>The List</Typography>
      <Detail className={classes.profile}><Profile /></Detail>
    </Box>
  );
}

export default Header;