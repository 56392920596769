import React, { useState } from "react";
import Field from "./Field";
import LoadButton from "../LoadButton";
import Typography from "@material-ui/core/Typography";
import { Auth } from "aws-amplify";

export type SignInConfirmProps = {
  challengeUser: any,
  onConfirm: () => void
};

const SignInConfirm: React.FC<SignInConfirmProps> = (
    { challengeUser, onConfirm }
  ) => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  const handleConfirm = () => {
    setLoading(true);
    Auth.confirmSignIn(challengeUser, code, challengeUser.challengeName)
      .then(() => onConfirm())
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Typography gutterBottom variant="body1">Enter authentication code:</Typography>
      <Field input={["Code", code, setCode]} />
      <LoadButton color="primary" variant="contained" loading={loading} onClick={handleConfirm}>Confirm</LoadButton>
    </>
  );
};

export default SignInConfirm;