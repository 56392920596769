import React, { useState } from "react";
import Field from "./Field";
import LoadButton from "../LoadButton";
import Typography from "@material-ui/core/Typography";
import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  setNewPasswordButton: {
    width: "100%"
  }
});

export type SignInNewPasswordProps = {
  challengeUser: any,
  onCompleteNewPassword: () => void
};

const SignInNewPassword: React.FC<SignInNewPasswordProps> = (
    { challengeUser, onCompleteNewPassword }
  ) => {
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const handleSetNewPassword = () => {
    setLoading(true);
    const requiredAttributes = {}; // Assume no required attributes
    Auth.completeNewPassword(challengeUser, newPassword, requiredAttributes)
      .then(() => onCompleteNewPassword())
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Typography gutterBottom variant="body1">Set a new password to continue.</Typography>
      <Field input={["New Password", newPassword, setNewPassword]} />
      <LoadButton
        color="primary"
        variant="contained"
        loading={loading}
        onClick={handleSetNewPassword}
        className={classes.setNewPasswordButton}
      >
        Set New Password
      </LoadButton>
    </>
  );
};

export default SignInNewPassword;