import React, { useState, useContext } from "react";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { SessionContext } from "../../../App";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    cursor: "pointer",
  },
  avatar: {
    width: 32,
    height: 32,
    position: "relative"
  },
  username: {
    padding: "4px 10px 0 0"
  }
});

const Profile: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const session = useContext(SessionContext);

  const handleOpen = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  }

  return (
    <Box className={classes.root} onClick={handleOpen}>
      <Typography variant="body1" component="span" className={classes.username}>{session.user.username}</Typography>
      <Avatar className={classes.avatar}>M</Avatar>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
      >
        <MenuItem onClick={() => session.setUser(null)}>Sign out</MenuItem>
      </Menu>
    </Box>
  );
}

export default Profile;