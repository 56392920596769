import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    "& button": {
      marginBottom: 0
    }
  },
  spinner: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
});

export interface LoadButtonProps extends ButtonProps {
  loading: boolean,
};

const LoadButton: React.FC<LoadButtonProps> = (
    { loading, children, ...props }
  ) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button
        disabled={loading}
        {...props}
      >
        {children}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          className={classes.spinner}
        />
      )}
    </div>
  );
};

export default LoadButton;