import React from "react";
import Header from "./Header";
import Box from "@material-ui/core/Box";

const Dashboard = () => {
  return (
    <>
      <Header />
      <Box>
        
      </Box>
    </>
  );
}

export default Dashboard;