import React from "react";
import TextField from "@material-ui/core/TextField";

export type FieldProps = {
  input?: [string, string, (newState: any) => void],
  type?: string,
  [prop: string]: any
}

const Field: React.FC<FieldProps> = ({ input, ...props }) => <TextField
  size="small"
  variant="outlined"
  required={true}
  {...input ? {
    label: input[0],
    value: input[1],
    onChange: (e) => input[2](e.target.value)
  } : {}}
  {...props}
/>;

export default Field;